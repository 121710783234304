.template-wrapper, .letter-wrapper {
    padding: 0 15%;
}

.template-wrapper form  {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.engagement .form-group label {
    flex: 0 0 100% !important;
}

div.engagement.modal-content {
    width: 80%;
}

.engagement-letter-title {
    line-height: 33px;
}
