.breadcrumb {
    &-item {
        text-transform: capitalize;
    }
}

#fileUrlContainer {
    display: none;
}

#fileUrl {
    display: inline-block;
    width: 87%;
}

#copyFileUrl {
    display: inline-block;
    vertical-align: top;
}

#feedbackText {
    white-space: pre-wrap;
}

#reminders {
    &Notification {
        &:hover {
            cursor: pointer;
        }

        & a {
            text-decoration: none;
        }
    }

    &Icon {
        color: rgba(44, 56, 74, 0.681);
        font-size: 1.25rem;
        vertical-align: middle;
    }

    &Quantity {
        left: 12px;
        top: -3px;
    }
}

.dataTables_length {
    width: 150px;

    & select {
        width: 60px;
        display: inline-block;
    }
}

.dataTables_filter {
    & input {
        width: 200px;
        display: inline-block;
    }
}