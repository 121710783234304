.section-header {
    height: 30px;
    line-height: 30px;
    vertical-align: middle;

    &-indicator {
        background-color: blue;
        float: left;
        min-height: 100%;
        width: 5px;

        &-unconfirmed {
            background-color: red;
        }
    }

    &-text {
        float: left;
    }
}

.yes-no-container {
    & label {
        margin-top: 2px;
        vertical-align: middle;
    }
}

.plus-inventory-text {
    margin-top: 2px;
}

.required, .incorrect-value, .incorrect-link {
    border: 1px solid red !important;
}

.icon-wrapper {
    width: 70px;
    text-align: center;
    margin: 0 auto;

    & i {
        font-weight: bold;
        font-size: 20px;

        &.has-value {
            color: green;
        }

        &.no-value {
            color: red;
        }
    }

    @include tablet {
        width: auto;
    }

    @include mobile {
        width: auto;
    }
}

.offer-type-icon-wrapper {
    width: 20px;
    text-align: center;
    margin: 0 auto;

    & i {
        font-size: 20px;
    }
}

.actions-column {
    width: 135px !important;

    @include tablet {
        width: auto;
    }

    @include mobile {
        width: auto;
    }
}

.actions-container {
    width: 126px;
    margin: 0 auto;

    @include tablet {
        width: auto;
    }

    @include mobile {
        width: auto;
    }

    & a {
        text-decoration: none;

        & i {
            color: white;
        }
    }
}

.values-column {
    width: 90px;
    max-width: 90px;

    @include tablet {
        width: auto;
    }

    @include mobile {
        width: auto;
    }
}

.y-n-column {
    width: 70px !important;

    @include tablet {
        width: auto;
    }

    @include mobile {
        width: auto;
    }
}

.action-icon {
    vertical-align: middle;
}

.confirmed {
    & i {
        color: blue;
        font-size: 22px;
        vertical-align: middle;
    }
}

.check-details {
    border: 1px solid !important;
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
}

.section-editable-field {
    line-height: 31px;
}

.input-group {
    max-height: 35px;
}

.csr-progress-bar {
    &-step {
        color: black;

        &-3 {
            color: gray;
        }
    }
}

.loi-header {
    line-height: 33px;
}

.show-loi-column-left {
    border-right: 1px solid #d8dbe0;

    @media (max-width: #{767px}) {
        border: 0;
    }
}