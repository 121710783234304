.upload {
    padding: 0;

    &-link {
        color: $darkest-gray;
        display: inline-block;
        text-decoration: none;
        width: 100%;
        height: 100%;
        padding: 0.375rem 0.75rem;

        &:hover {
            text-decoration: none;
            color: $darkest-gray;
        }
    }
}

.progress-bar2 {
    background-color: black;
    height: 2px;
    display: none;
}

.percent2 {
    display: none;
}

.getFile {
    display:none;
}

.uploaded {
    cursor: auto;

    &-pl {
        line-height: 37px;

        @include mobile {
            display: block;
        }
    }

    &-action {
        float: right;

        &-delete {
            cursor: pointer;
        }

        @include mobile {
            display: block;
            float: left;
        }
    }
}

.edit-actions {
    display: none;
}

.editable {
    cursor: default;

    &-textarea {
        white-space: pre-line;
        float: left;
    }

    &-textarea-edit {
        float: right;
        padding-left: 5px;
    }

    &-field-name {
        display: inline-block;
    }

    &-description {
        vertical-align: top;
    }

    &-description-save {
        vertical-align: top;
    }

    &-default {
        display: inline-block;
    }

    &-editing {
        display: none;
        width: 61%;
    }

    &-editing-all {
        width: 100%;
    }

    &-edit {
        cursor: pointer;
        display: none;
    }

    &-input {
        display: inline-block !important;
        width: calc(100% - 20px) !important;
    }

    &-save {
        cursor: pointer;
    }

    .radio-buttons {
        float: left;
        height: 20px;
        width: 100px !important;

        label {
            float: left;
            clear: none;
            display: block;
            padding: 0px 6px 0px 4px;
            margin: 0;
        }

        input[type=radio],
        input.radio {
            float: left;
            clear: none;
            margin: 2px 0 0 2px;
        }
    }
}

.label-pill {
    font-weight: 600;
    margin-bottom: 3px;

    & .label-text {
        vertical-align: middle;
        line-height: 1.5;
    }

    & .status-pill {
        line-height: 1.5;
    }

    & .remove-label {
        font-size: 11px;
        font-weight: 600;
        line-height: 1.2;
    }
    & .download-nda {
        font-size: 11px;
        font-weight: 600;
        line-height: 1.2;
    }
}

.capsule-description {
    .editable-edit {
        vertical-align: top;
    }
}

.exception {
    background: white;
    border-radius: .25rem;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .14),0 1px 10px 0 rgba(0, 0, 0, .12),0 2px 4px -1px rgba(0, 0, 0, .2);
    display: none;
    margin-right: 20px;
    position: absolute;
    z-index: 1000;

    &-emails {
        margin-bottom: 5px;
    }

    &-email {
        margin-left: 0 !important;
    }
}

#inquiryNotes {
    max-height: 280px;
    overflow-x: hidden;
    overflow-y: auto;
}

#inquiryLogs {
    max-height: 280px;
    overflow-x: hidden;
    overflow-y: auto;
}

.tooltip {
    white-space: pre-line !important;
}

.add-remove-labels {
    display: inline-block;
    text-decoration: none;
    vertical-align: middle;
    text-wrap: nowrap;
}

.inquiryLabelsList {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}

.remove-label {
    cursor: pointer;
}

.download-nda {
    cursor: pointer;
}

#searchbar-tooltip {
    font-size: 9px;
    vertical-align: middle;
}

.inquiry-icons-container {
    margin-top: 3px;
    width: 55px;
}

.note-icons-container {
    margin: auto;
    width: 25px;

    &:hover {
        cursor: pointer;
    }

    & .view-inquiry-notes {
        font-size: 24px;
    }

    & .notes-quantity {
        left: 15px;
        top: -5px;
    }
}

.reminder-icons-container {
    &:hover {
        cursor: pointer;
    }

    & .view-inquiry-reminder {
        font-size: 24px;
    }

    & .add-reminder-dropdown {
        width: 200px;

        & .create-reminder {
            width: 100%;
        }

        & .custom-reminder-form {
            border-top: 1px solid #ced2d8;
        }
    }

    & .manage-reminder-dropdown {
        width: 100%;

        & button {
            width: 100%;
        }
    }
}

.labels-filter {
    min-width: 290px !important;

    @include mobile {
        right: 0;
    }
}

.date-filter {
    min-width: 360px !important;

    @include mobile {
        min-width: 300px !important;
        right: 0;
    }
}

.inquiries-filter-option {
    width: 77px;
    height: 37px;
    text-align: center;
}

.export-filtered-inquiries-button-auto {
    visibility: hidden;
    position: absolute;
    top:0;
}

table.dataTable.compact thead th {
    padding-left: 5px !important;
}

.paginate_button {
    padding: 0 !important;
}

.listing-name {
    &-container {
        @include mobile {
            width: 300px;
        }
    }
    &-input {
        min-width: 450px;

        @include mobile {
            min-width: 100%;
        }
    }
}

.lois-list {
    line-height: 26px;

    & input {
        min-height: 23px;
        vertical-align: middle;

        &:checked+label {
            background-color: #39f;
            color: white;

            & a {
                text-decoration: none;
                color: white;
            }
        }
    }

    & label {
        border-radius: 4px;
        padding: 2px 6px;
    }
}
