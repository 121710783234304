textarea:focus, input:focus{
    outline: none!important;
    box-shadow: none!important;
}

#select-owners {
    min-width: 180px;
}

.listing-row {
    cursor: pointer;
}

.dropdown-toggle.collapsed::after {
    border-top: 0;
    border-bottom: .3em solid;
}
.search {
    &-listings {
        border: 1px solid #aaa;
        border-radius: .25rem 0 0 .25rem;
        float: left;
        height: 28px;
        width: 80%;
    }

    & button {
        border-radius: 0 .25rem .25rem 0;
        float: right;
        height: 28px;
        width: 20%;
    }
}

input#simpleSearch {
    color: #5c6873;
    background-color: #fff;
    -webkit-appearance: textfield;
    padding: 0.375rem 0.75rem;
    border: 1px solid #e4e7ea;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

input#simpleSearch:focus {
    z-index: 3;
    border-color: #8ad4ee;
}
input[type="search" i]:not(:placeholder-shown)::-webkit-search-cancel-button {
    opacity: 1 !important;
}
#advanced-search-field-template {
    height: 0;
    visibility: hidden;
}

.filter-container {
    & .custom-search {
        display: inline-block;
    }

    & .select {
        &-filter {
            width: 25%;
        }

        &-operator {
            width: 25%;
            padding: 0 4px 0 4px;
        }
    }

    & .search-string-input {
        width: calc(50% - 40px);
        margin-right: 5px;
    }

    & .remove-filter {
        width: 34px;
    }

    &:first-child {
        & .search-string-input {
            width: 50%;
            margin-right: 0;
        }

        & .remove-filter {
            display: none;
        }
    }
}

.listing-sort.dropdown-toggle::after {
    display:none;
}
.pagination {
    display: -ms-flexbox;
    flex-wrap: wrap;
    display: flex;
}

.rounded-pill:first-child {
    margin-left: 0 !important;
}