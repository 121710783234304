.custom-tooltip {
    position:absolute;
    display:none;
    background-color:#FFF;
    border:1px solid #d8dbe0;
    border-radius:5px;
    padding:0 10px 10px 10px;
    left:200px;
}

.delete-question {
    color:#CC0000;
    cursor:pointer;
    font-size:14px;
}

.delete-section {
    color:#CC0000;
    cursor:pointer;
    font-size:18px;
}

.edit-section{
    color:green;
    cursor:pointer;
    font-size:18px;
}

.edit-question {
    color:green;
    cursor:pointer;
    font-size:14px;
}

.editing-section, .adding-section {
    font-size: 2.1875rem !important;
    float:left;
}

.adding-question, .editing-question {
    float:left;
}

.clear-both {
    clear:both;
}

.section-parent .cis-check, .section-parent .cis-x {
    font-size:36px;
    margin-top:10px;
    display:inline-block;
    cursor: pointer;
    float:left;
}

.question .cis-check, .question .cis-x, .add-question-container .cis-check, .add-question-container .cis-x{
    font-size:18px;
    margin-top:5px;
    cursor: pointer;
    display:inline-block;
    float:left;
}

.arrow-right:after {
    -ms-transform: rotate(-90deg); /* IE 9 */
    -webkit-transform: rotate(-90deg); /* Chrome, Safari, Opera */
    transform: rotate(-90deg);
}

.auto-overflow {
    height: calc(100vh - 200px);
    overflow-y:auto;
}

.adding-question {
    min-width:290px;
}

.add-question {
    color:#2eb85c;
    cursor:pointer;
    font-size:16px;
}

.add-section {
    margin-top:15px;
}

span.controlInputSize {
    max-width: 86%;
    display: none
}

.interview-questions tr:first-child {
    border-top: 1px solid #d8dbe0;
}

.interview-sections tr.interview-section:first-child {
    border-top: 1px solid #d8dbe0;
}

#interviewAddModal {
    overflow-y: scroll;
}

.convertedName {
    cursor: grab;
}

.interview {
    cursor: grab;
}