a.recover-password {
    font-size: 14px;
    height: 100%;
    width: 148px;
    margin-left: -24%;
    @include mobile {
        width: 130px;
        font-size: 12px;
    }
}

button.login {
    width: 115px;
    @include mobile {
        width: 106px;
    }
}
