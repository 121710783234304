.all-switcher {
    height: 25px;
}

.tasks-table {
    & tr {
        & td:last-child {
            text-align: center;
        }

        & th:last-child {
            text-align: center;
        }
    }
}