.differences-by-period {
    display: inline-block !important;
    margin-right: 10px;
    padding: 10px;

    & p {
        margin: 0;
    }

    & .quantity {
        margin-right: 5px;
    }
}

.date-range-title {
    padding-left: 24px !important;
}

.date-picker-container {
    float: right;
    width: 300px !important;
}

.widgets-container {
    clear: both;
}

.date-range-label {
    line-height: 35px;
}

#inquiriesByStatuses {
    border-bottom: 1px solid #d8dbe0;
}

.inquiries-by-status {
    display: inline-block !important;
    margin-right: 10px;
    padding: 10px;
    width: 141px;

    & p {
        margin: 0;
    }

    & .quantity {
        margin-right: 5px;
    }
}