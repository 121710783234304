// Here you can add other styles


.c-header.c-header-fixed{
    top:0px;
}

.click-file{
    cursor:pointer;
}

.c-header-nav-item .dropdown-menu{
    min-width: 180px;
}

@media only screen and (max-width: 587px) {
    .custom-column-padding {
        padding-left: 0 !important;
    }
}

@media only screen and (min-width: 576px) and (max-width: 586px) {
    .custom-float-right {
        margin-left: 100px !important;
    }
}

.nda-template-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.preview-nda .note-editor.note-frame .note-editing-area .note-editable {
    padding: 30px 30px !important;
}

@media (min-width: 992px) {
    .preview-nda .note-editor.note-frame .note-editing-area .note-editable {
        padding: 50px 100px !important;
    }
}