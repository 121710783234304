.loader {
    background: $transparentBlack;
    display: none;
    min-height: 100vh;
    position: fixed;
    width: 100vw;
    z-index: 10000;

    &-container {
        width: 200px;
        height: 200px;
        line-height: 100vh;
        margin: 0 auto;
        padding-top: 40vh;
    }
}

.to-listing {
    display: inline-block;
    margin-bottom: 10px;
    text-decoration: underline;

    &:hover {
        color: $orange;
        cursor: pointer;
    }
}

.upload {
    margin-bottom: 15px;
}


.hide {
    display: none;
}

.grab {
    cursor: grab;
    cursor: -webkit-grab;
    cursor: url(../favicon/openhand.cur), default;
}

.grab:active {
    cursor:         grabbing;
    cursor: -webkit-grabbing;
    cursor:    -moz-grabbing;
}

.pointer {
    cursor: pointer;
}

.addBackModal div.inputButton {
    width: 35%;
}

.addbackWrapper input.form-control {
    max-width: 65px;
}

#lineItemsDropdownMenuButton {
    padding: 5px 5px 0 5px;
}

.uncategorized-data-row.select-single {
    border-bottom: 1px solid #d8dbe0;
    margin-bottom: 0;
    padding-top: .5rem;

    &:hover {
        background-color: #e7ebf0;
        border-bottom: 1px solid #c4c8cf;
    }
}
