@media screen and (max-width: 767px) {
    .csrs-table-container {
        overflow-x: scroll;
        overflow-y: hidden;
    }
}

@media screen and (max-width: 1024px) {
    .actions-container {
        & a, & button {
            margin-right: 0 !important;
            margin-bottom: 4px;
        }
    }
}